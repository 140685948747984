.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f5f5f5;
}

.aviso {
    color: #b60321;
    font-size: 10px;
    margin-bottom: 15px;
    margin-top: -8px;
    max-width: 600px;
    padding-left: 5px;
    text-shadow: 0 0 1px rgba(169, 19, 31, .329);
    width: 100%;
}

.declarationSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    width: 100%;
    max-width: 600px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.declarationItem {
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

label {
    margin-right: 10px;
    font-size: 16px;
    color: #1F4B73;
    width: fit-content;
}

.navigationButtons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
    margin-top: 20px;
}

button {
    border: solid 2px #1F4B73;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
}

button svg {
    fill: #1F4B73;
    margin: 0px 3px;
}

button:hover {
    background-color: #164066;
}

button:focus {
    outline: none;
}

.declarationForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 600px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;

    p {
        margin-top: 10px;
        /* margin-bottom: 50px; */
        line-height: 150%;
    }
}

.declarationFormTitle {
    margin-bottom: 10px;
}

.declarationFormSubTitle {
    margin-bottom: 30px;
}

.declarationFormNameTitle {
    margin-bottom: 30px;
}

.declarationContent {
    font-size: 14px;
    color: #333;
    margin: 20px 0px;
    text-align: justify;
}

.declarationContent span {
    font-weight: bold;
}

.declarationContent p {
    margin-bottom: 20px;
}

.radioGroup {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.radioGroup label {
    margin-right: 10px;
    font-size: 16px;
    color: #1F4B73;
}

.radioGroup input {
    margin-right: 5px;
}

.additionalFields {
    margin-top: 20px;
}

.inputGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.inputGroup label {
    font-size: 14px;
    color: #333;
    margin-bottom: 5px;
}

.inputGroup input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

/* Responsividade para dispositivos móveis */
@media (max-width: 600px) {
    .radioGroup {
        flex-direction: column;

        label {
            font-size: 14px;
        }
    }


    .declarationForm,
    .navigationButtons,
    .declarationSection {
        padding: 15px;
    }

    .declarationContent {
        padding: 10px 0px;
    }

    h1,
    h2,
    h3 {
        padding: 5px 0px;
    }

    h1 {
        font-size: 18px;
    }

    h2 {
        font-size: 16px;
    }

    h3 {
        font-size: 14px;
    }


    button {
        width: 100%;
        /* margin-bottom: 10px; */
    }
}

.centeredButton {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.declarationForm {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    text-align: justify;
}

.declarationContent {
    margin-bottom: 20px;
}

.fieldGroup {
    margin-bottom: 15px;
}

.fieldGroup label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.fieldGroup input,
.fieldGroup select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.fileUpload {
    margin: 20px auto;
    text-align: center;
}

.childForm {
    margin-bottom: 30px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #fff;
}

.childForm h4 {
    margin-bottom: 20px;
}

.navigationButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.radioGroupInput {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.declarationConfirm {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1F4B73;
    font-weight: 500;
    margin: 0px;
}

.LinkGerarRelatorio {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    text-decoration: none;

    button {
        cursor: pointer;
        font-weight: bold;
    }
}

.formFilePicker {
    display: flex;
    justify-content: center;
    align-items: center;

    label {
        margin: 5px 0px;
    }

    input {
        margin: 10px 0px;
    }
}