@import '../../Assets/theme/colors.module.scss';

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;

    button {
        min-width: 250px;
    }

    .error {
        color: $error;
        font-size: 16px;
        font-weight: 600;
    }
}

.month {
    color: $primary;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
    &::first-letter{
        text-transform: capitalize;
    }
    /* text-transform: capitalize; */
}

.div_text {
    display: flex;
    width: 100%;
    margin: 20px 0px;
    justify-content: center;
    text-align: center;
    .text {
        text-align: justify;
        // width: 60%;
        color: $grey;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 20px;
    }
}


@media(max-width: 600px) {
    .text {
        font-size: 14px;
        width: 260px;
        display: flex;
        margin: 15px auto;
    }
}