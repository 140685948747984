@import '../../Assets/theme/colors.module.scss'; 
.container {
    display: flex;
    flex-direction: column;
    background-color: $primary;
    border-radius: 8px;
    padding: 20px 30px;
    min-height: 65vh;
    flex-grow: 1;
    max-width: min(100%, 420px);
    .title {
        text-align: center;
        flex-grow: .3;
        span {
            color: white;
        }

        h1 {
            color: white;
            font-weight: bold;
        }
    }
    .child{
        display: flex;
        flex-grow: 1;
        margin-top:64px;
        button{
            border-color: white;
        }
    }
}