@import '../../Assets/theme/colors.module.scss';
.inputs{
    label:first-child{
        color: white !important;
    }
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
    
}
button{
    place-self: center;
}