@import '../../../Assets/theme/colors.module.scss';
.container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $primary;
    height: 100%;
    width: 300px;
    border-top-right-radius: 8px;
    padding: 100px 40px 40px 40px;
    gap:24px;
    // box-shadow: 2px 2px .5px $grey;
    // float: right;
    overflow-y: scroll;
    scrollbar-width: none;
    @media(max-width: 870px){
        width: 150px;
        padding: 100px 10px 40px 10px;
    }
    @media(max-width: 700px){
        display: flex;
        width: 50px;
        padding: 50px 5px 50px 5px;
    }
    .logout{
        // color: $error;
        // svg{
        //     color: $error;
        // }
        &:hover{
            background-color: $error;
            color: white;
            svg{
                color:white;
            }
        }
    }
}

// .hide{
//     position: absolute;
//     height: calc(100% - 80px);
//     z-index:999;
// }