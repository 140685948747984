@import '../../Assets/theme/global.module.scss';
@mixin keyframes($name){
    @keyframes #{$name} {
        @content
     }
}
@mixin animate($animation,$duration,$method,$mode){
    animation: $animation $duration $method $mode;
}

.container{
    position: relative;
    height: 180px;
    width: min(80%,180px); 
    cursor: pointer;
    border-radius: 8px;
    display: flex; 
    flex-direction: column; 
    background-color: $primary; 
    color: white;
    overflow: hidden;
    // box-shadow: 1.7px 1.5px 1.8px 1.8px $grey;
    box-shadow:  0px 0px 15px -2px $grey;
    .content{
        position: relative;
        padding: 16px; 
        display: flex;
        // flex-direction: column;
        justify-content: space-evenly; 
        align-items: center;
        svg{
            color: white;
        }
        // flex-grow: 1;
    }
    // &:hover{
        // opacity: .5;
        // .content{
        //     flex-direction: row;
        //     align-items: center;
        //     justify-content: center;
        //     flex-grow: 0;
        //     gap: 8px;
        //     svg{
        //         height: 20px;
        //         width: 20px;
        //     }
        // }
        // .description{
        //     @include animate(show-description,.3s,ease-in-out, forwards)
        // }

       
    // }
   
}
.description{
    // visibility: hidden;
    // position: absolute;
    // transform: translateY(100%);
    font-size: 14px;
    // bottom: 0;
    // border: 1px solid transparent;
    height: 100%;
    padding: 12px 8px;
    border-radius: 8px;
    background-color: white;
    color: $primary;
    border: 2px solid $primary;
    font-weight: 500;
    text-align: center;
    // height: 60%;
    // @include keyframes(show-description){
    //     to{
    //         transform: translateY(0);
    //         visibility: visible;
    //     }
    // }

}

