.container {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    .grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        place-items: center;
        gap: 10px 10px;
    }
}

@media(max-width:700px) {
    .container {
        justify-content: center;
        align-items: center;

        label {
            text-align: center;
        }

        .grid {
            grid-template-columns: 1fr;
            gap: 5px 5px;
        }
    }
}