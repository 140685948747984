@import '../../Assets/theme/colors.module.scss';
.player {
   iframe {
      aspect-ratio: 16/9;
   //  height: 490px;
   //  width: 740px;
   }
    
}
.container{
   background-color: white;
   cursor: pointer;
   padding: 4px 8px;
   border-radius: 12px;
   &[data-theme="default"]{

      position: absolute !important;
      top:50%;
      transform: translateY(-50%) translateX(-60%);
   }
   &[data-theme="secondary"]{
      background-color: $primary;
      .tutorial{
         color:white;
      }
   }
   &:hover{
      background-color: $success;
      color: white;
      .tutorial{

         .play{
            color:white;
         }
      }
   }
      .tutorial{
         display: flex;
         flex-direction: column;
         align-items: center;
         gap: 8px;
         margin: 4px 8px;
         .play{
            color: $success;
            scale: 1.2;
         }
      }
}
@media (max-width: 768px) {
   .container{
      .tutorial{
         font-size: 0;
         gap: 0;
         .play{
            scale: 1.5 !important;
         }
      }
   }
    .player {
        iframe {
         height: 250px;
         width: 100%;
        }
     }
}