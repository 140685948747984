@import '../../Assets/theme/colors.module.scss';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 0px auto;
    width: 90%;
    padding: 32px 0px;

    fieldset {
        display: inherit;
        flex-direction: inherit;
        width: inherit;
    }

    .formcontainer {
        margin: auto;
        // width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 600px;
        fieldset {
            display: inherit;
            flex-direction: inherit;
            align-items: inherit;
            width: inherit;
            
        }
    }

    h1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: $primary;
        font-size: 28px;
        margin-bottom: 16px;

        +p {
            margin-bottom: 16px;
            margin-top: -16px;
            text-align: center;
            font-size: 16px;
            color: $grey;
        }
    }
}

.subTitle {
    margin-bottom: 30px;
}

.aviso {
    width: 100%;
    max-width: 600px;
    color: #b60321;
    font-size: 10px;
    margin-top: -8px;
    margin-bottom: 15px;
    padding-left: 5px;
    text-shadow: 0px 0px 1px #a9131f54;
}

.actions {
    margin-top: 16px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    // padding-left: 48px;
}

@media(max-width: 450px) {
    .container {
        padding: 0px;
        width: auto;

        .formcontainer {
            margin: 1px 1px;
            width: 90%;
            padding: 5px;
        }
    }
}

@media(max-width: 600px) {
    .container {
        padding: 20px 5px;
        width: 90%;

        h1 {
            font-size: 20px;
        }
    }
}

@media(min-width: 1000px) {
    .container {

        .formcontainer {

            justify-content: center;
            width: 60%;
        }
    }
}