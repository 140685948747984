.grid {
    display: grid;
    width: 100%;
    gap: 0px 20px;
    grid-template-columns: 1fr 1fr;
}

@media(max-width:600px) {
    .grid {
        grid-template-columns: 1fr;
    }
}