@import './colors.module.scss';
@import url("https://fonts.googleapis.com/css2?family=Zain:wght@200;300;400;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@160..700&family=Zain:wght@200;300;400;700;800;900&display=swap");
body,html {
    margin: 0;
    padding: 0;
    font-family: "Readex Pro", "Zain", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $primary;
    height: 100vh !important;
}

div[id="root"]{
    height: 100% !important; 
}

fieldset{
    border: none;
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    svg{
        color: $primary;
    }
}

input[type="checkbox"]{
    accent-color: $primary;
    // clip-path: circle();
    width: 1.2em;
    height: 1.2em;
    border-radius: 0.2em;

}

