@import '../../../../Assets/theme/colors.module.scss';

.container {
    position: relative;
    background-color: $primary;
    min-height: 80px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 40px;
    margin: 0;

    .logo {
        -webkit-user-drag: none;
        margin: auto;
        width: auto;
        height: 70px;
    }

    .hamburger {
        color: white;
        cursor: pointer;
        -webkit-user-drag: none;
        width: 50px;
        height: 50%;
        margin-right: 7%;
        &:focus-visible{
            // outline: none;
            border-color: white;
        }
    }
}

@media(max-width: 600px) {
    .container {
        .logo {
            width: auto;
            height: 50px;
        }

        .hamburger {
            margin-right: 0%;
        }
    }
}