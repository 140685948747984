@import '../../Assets/theme/colors.module.scss';
.button{
    box-shadow: none !important;
    width: fit-content !important;
    border: 2px solid transparent !important;
    &:focus-visible{
        border: 4px solid black !important;
    }
    
}

.confirm{
    background-color: $primary !important;
    @extend .button;
    
}
.cancel{
    background-color: $error !important;
    @extend .button

}
.text{
    font-size: large !important;
    color: $lightgrey;
}
.title{
    font-size: 32px !important;
}
.popup{
    border-radius: 8px !important;
}

.actions{
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap;
}

.close{
    color: $primary;
    &:hover{
        color: $error;
    }
}