@import "../../Assets/theme/colors.module.scss";

.container{
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    .label {
        font-weight: 600;
        font-size: 16px;
        color: $grey;
        &::first-letter{
            text-transform: uppercase;
        }
    }
    .wrapper{
        position: relative;
        display: flex;
        // flex-grow: 1;
        flex-direction: row;
        align-items: center;
        // gap: 2px;
        // outline-width: 1.5px;
        // outline-style: solid;
        // outline-color: $lightgrey;
        border: 1.5px solid $lightgrey;
        border-radius: 8px;
        // outline: none;
        // height: 35px;
        min-height: 35px;
        background-color: white;
        // max-width: 600px;
        .input, input{
            display: flex;
            flex-grow: 1;
            padding: 6px 8px;
            outline: none;
            border: none;
            border-radius: 8px;
            width: 100%;
            height: 100%;
            // padding: 6px 8px 6px 8px;
            font-weight: 500;
            color: $grey;
            font-size: 14px;
            // width: calc(100% - 40px);
            // max-width: 600px;
            background-color: white;
            @media(max-width:400px) {
                font-size: 12px;
            }
         
        }
        &.error {
            // padding: 6px 38px 6px 8px;
            border-color: $error !important;
        }
    
        &.success {
            // padding: 6px 38px 6px 8px;
            border-color: $success !important;
        }
        
    }

}
.icon{
    display: flex;
    flex-grow: 1;
    // top:0;
    // position: absolute;
    // right: 2px;
    width: 32px;
    // aspect-ratio: 1;
    // height: 100%;
    // transform: translateX(calc(-100% - 2px));
}
.noPadding{
    padding: 0;
}
.errorlabel {
    place-self: start;
    color: $error;
    font-size: 12px;
    font-weight: 800;
    height: 16px;
    & + .container {
        margin-top: 10px;
    }
}
.textarea{
    border: 1px solid $lightgrey;
    min-width: 100%;
    width: 100%;
    // width: fit-content;
    background-color: white;
    padding: 6px 8px;
    border-radius: 8px;
    max-width: 600px;
    textarea{
        font-weight: 500;
        color: $grey;
        font-size: 14px;
        border:none;
        min-width: 100%;
        max-width: 100%;
        outline: none;
    }
    &.error {
        // padding: 6px 38px 6px 8px;
        border-color: $error !important;
    }

    &.success {
        // padding: 6px 38px 6px 8px;
        border-color: $success !important;
    }
}
