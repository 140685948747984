@import '../../../Assets/theme/colors.module.scss';

.container {
    border-collapse: collapse;
    background: none !important;
    border: none;
    // text-align: center;
    color: $primary;
    font-size: 14px;
    font-weight: 500;
    width: auto;
    white-space: nowrap;

    &.divider {
        border-right: 1px solid $lightgrey;
    }

    .content {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        // justify-content: center;
        // flex-grow: 1;
        button {
            font-size: 12px;
            align-items: center;
            justify-content: center;
        }

    }
}

@media (max-width: 1080px) {
    .container {
        font-size: 10px;

        .content {
            button {
                width: 80px;
                height: 30px;
                font-size: 9px;
            }
        }
    }

}