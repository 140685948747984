.informative {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 30px 0px;
    padding: 0px 20px;

    .row {
        display: flex;
        flex-direction: column;
        gap: 10px;
        flex-wrap: wrap;
    }
}

.spanInstituicao {
    width: 100%;
    display: flex;
    display: flex;
    gap: 5px;
    align-items: center;

    span {
        font-size: 15px;
    }

    label {
        font-weight: 500;
        font-size: 16px;
    }

}

.divSpan,
.divPeriodo {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;

    span {
        font-size: 15px;
    }

    label {
        font-weight: 500;
        font-size: 16px;
    }
}