@import '../../../Assets/theme/colors.module.scss';
.container {
    background-color: $primary;
    color: white;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 5px;
    border-radius: 8px;
    font-weight: 700;
    border-width: 2px;
    @media(max-width:600px){
        justify-content: center;
    }
    svg{
        color: white;
        // aspect-ratio: 16/9;
        width: 30px;
        height: 30px;
        transition: all .2s ease-in-out;
        @media(max-width:769px){
            width: 20px;
            height: 20px;
        }
    }
    .text{
        text-align: start;
        margin-left: 24px;
        &::first-letter{
            text-transform: capitalize;
        }
        font-size: 16px;
        @media(max-width:879px){
            margin-left: 10px;
            font-size: 11px;
        }
        @media(max-width:700px){
            display: none;
        }
    }
    &.active, &:hover {
        color: $primary;
        background-color: white;
        border: 2px solid white;

        svg{
            color: $primary;
        }
    }
    &:focus-visible{
        border-color: white;
    }
    transition: all .2s ease-in-out;

    & + .container{
        margin-top: 24px;
        // @media(max-width:600px){
        //     margin-top: 15px;
        // }
    }
}