.container{
    text-align: center;
    &::first-letter{
        text-transform: capitalize;
    }
    span{
        font-size: 13px;
        text-wrap: nowrap;
        @media(max-width:1090px){
            font-size: 11px;
            text-wrap: wrap;
            line-height: normal;
        }
        @media(max-width:890px){
            font-size: 10px;
            text-wrap: wrap;
            line-height: normal;
        }
    }
    

}