@import '../../../../../../Assets/theme/colors.module.scss';

.container {
    width: 100%;
    max-width: 600px;
    border-radius: 8px;
    border: 1px solid $lightgrey;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 4px 10px;

    .label {
        font-size: 14px;
        font-weight: 400;
        /* color: $grey; */
        color: $primary;

    }

    &+& {
        margin-top: 12px;
    }
}

@media(max-width:400px) {
    .container {
        .label {
            font-size: 13px;
        }
    }

}

@media(max-width:700px) {
    .container {
        flex-direction: column;
        gap: 5px;

    }
}