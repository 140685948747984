.container{
    display: flex;
    flex-direction: column;
    width: 100%;
    
    >h1{
        text-align: start;
        font-size-adjust: inherit;
        // font-size: 1em;
    }
    .selection{
        margin-top: 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 24px;
        // width: 40%;
        @media(max-width:1300px){
            width: 70%;
            font-size: 14px;
        }
        @media(max-width:660px){
            flex-direction: column;
            gap: 0px;
            width: 100%;
            font-size: 14px;
        }   
        :last-child{
            max-width: 400px;
        }
    }
}