@import '../../Assets/theme/colors.module.scss';

.inputs {
    label:first-child {
        color: white !important;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    p {
        place-self: end;
        color: white;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
    }

}

button {
    place-self: center;
}

.actions {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

