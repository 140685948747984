@import '../../Assets/theme/colors.module.scss';

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    user-select: none;

    .label {
        &::first-letter {
            text-transform: capitalize;
        }

        font-weight: 600;
        font-size: 16px;
        margin-bottom: 10px;
        width: -moz-fit-content;
        width: fit-content;
        min-width: fit-content;
        min-width: -moz-fit-content;
        // text-transform: capitalize;
    }

    .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 0px 10px;

        .option {
            display: flex;
            flex-direction: row;
            gap: 3px;
            font-weight: 600;
            font-size: 16px;

            .radio {
                display: inline;
                vertical-align: middle;
                accent-color: $primary;
            }
        }

        .wrapper {
            position: relative;
            border-radius: 25px;
            background-color: $lightgrey;
            width: 50px;
            height: 10px;
            display: flex;
            justify-content: center;
            align-items: center;

            &.checked {
                background-color: $success;

                .toggle {
                    left: 25px;
                }
            }

            .toggle {
                position: absolute;
                left: 5px;
                height: 20px;
                width: 20px;
                border-radius: 100%;
                background-color: $primary;
                transition: all .2s ease;
            }

            .checkbox {
                display: none;
            }
        }
    }

    .errorwrapper {
        min-height: 14px;
        display: flex;
        margin-top: 4px;

        .error {
            color: $error;
            font-size: 12px;
            font-weight: 800;
        }
    }
}

@media(max-width:420px) {
    .container {
        .label {
            font-size: 14px;
        }
    }
}