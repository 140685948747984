@import '../../../Assets/theme/colors.module.scss';

.step {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    margin-right: 20px;

    @media(max-width: 870px) {
        margin-right: 10px;
    }

    @media(max-width: 600px) {
        margin-right: 0px;
        padding: 4px;
    }

    &.clickable {
        cursor: pointer;

        &:hover {
            opacity: .8;
        }
    }
    &:focus-visible{
        outline: none;
        border: 2px solid $primary;
        border-radius: 8px;
    }
    .contentwrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        user-select: none;
        
        .container {
            position: relative;
            height: 35px;
            width: 35px;
            border-radius: 50%;
            background-color: $lightgrey;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            font-weight: 800;
            color: white;
            svg{
                transition: color .4s ease !important;
            }
            @media(max-width: 600px) {
                height: 29px;
                width: 29px;
            }
            &.active {
                background-color: $primary ;

                svg {
                    color: $primary ;
                }
            }

            &.completed {
                background-color: $success;

                svg {
                    color: $success;
                }
            }

            &:has(svg) {
                background-color: transparent !important;

                svg {
                    background-color: transparent;
                    color: $lightgrey;
                }
            }

            &::after {
                content: '';
                position: absolute;
                left: 100%;
                width: 40px;
                border: 4px solid $primary;

                @media(max-width: 870px) {
                    width: 30px;
                }

                @media(max-width: 600px) {
                    width: 10px;
                    border: 2px solid $primary;
                }
            }
        }

        .label {
            text-align: center !important;
            font-size: 16px;
            font-weight: 600;
            color: $lightgrey;
            margin: 8px 0px 0px 0px !important;
            @media(max-width: 600px) {
                display: none;
            }

            &.active {
                color: $primary;
            }
        }

        &.vertical {
            .container::after {
                display: none;
            }
        }


    }

    &:last-child {
        .container {
            &::after {
                display: none;
            }
        }

        margin-right: 0;
    }

}

@media(max-height:756px) {
    .step {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px;
        margin-right: 5px;

        @media(max-width: 870px) {
            margin-right: 10px;
        }

        @media(max-width: 600px) {
            margin-right: 0px;
            padding: 3px;
        }

        &.clickable {
            cursor: pointer;

            &:hover {
                opacity: .8;
            }
        }

        .contentwrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            user-select: none;

            .container {
                position: relative;
                height: 35px;
                width: 35px;
                border-radius: 50%;
                background-color: $lightgrey;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
                font-weight: 800;
                color: white;
                @media(max-width: 600px) {
                    height: 29px;
                    width: 29px;
                }
               
                &.active {
                    background-color: $primary ;

                    svg {
                        color: $primary ;
                    }
                }

                &.completed {
                    background-color: $success;

                    svg {
                        color: $success;
                    }
                }

                &:has(svg) {
                    background-color: transparent !important;

                    svg {
                        background-color: transparent;
                        color: $lightgrey;
                    }
                }

                &::after {
                    content: '';
                    position: absolute;
                    left: 100%;
                    width: 40px;
                    border: 4px solid $primary;

                    @media(max-width: 870px) {
                        width: 30px;
                    }

                    @media(max-width: 600px) {
                        width: 10px;
                        border: 2px solid $primary;
                    }
                }

            }

            .label {
                text-align: center !important;
                font-size: 14px;
                font-weight: 600;
                color: $lightgrey;
                margin-top: 8px;

                @media(max-width: 600px) {
                    display: none;
                }

                &.active {
                    color: $primary;
                }
            }

            &.vertical {
                width: 40px;
                height: 40px;
                margin-bottom: 5px;

                .label {
                    display: none;

                }

                .container::after {
                    display: none;
                }
            }

        }

        &:last-child {
            .container {
                &::after {
                    display: none;
                }
            }

            margin-right: 0;
        }

    }

}