@import '../../Assets/theme/colors.module.scss';


    
.inputs {
    label:first-child {
        color: white !important;
    }

    display: flex;
    flex-direction: column;
    gap: 24px;

    p {
        place-self: end;
        color: white;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
    }

    button {
        place-self: center;
    }
}

a {
    text-decoration: none;
    font-size: 14px;
    color: white;
    place-self: center;
    font-weight: 500;
}

 


