@import '../../Assets/theme/colors.module.scss';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    // min-height: 100%;

    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        flex-grow: 1;
        width: max(500px,50%);
        // max-width: 600px;
    }

    .actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }

}

@media(max-width:500px) {
    .container {
        .content {
            width: 90%;
        }
    }

}

@media(max-width:800px) {
    .container {
        .content {
            width: 70%;
        }
    }

}