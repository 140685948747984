@import '../../../../../Assets/theme/colors.module.scss';

.text {
    color: $primary;
    text-align: justify;
    font-size: 16px;
    font-weight: 600;

    span {
        font-size: 16px;
        font-weight: 700;
        color: $error;
    }
}