.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    &::first-letter{
        text-transform: capitalize;
    }
    /* text-transform: capitalize; */
    button {
        scale: .8
    }
}

@media(max-width:500px) {
    .container {
        flex-direction: column;
        gap: 10px;

        h3 {
            font-size: 16px;
        }
    }
}