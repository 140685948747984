@import '../../../Assets/theme/colors.module.scss';
.information{
    margin-top: 24px;
    .title{
        text-decoration: underline solid $primary 15% ;
        margin-bottom: 12px;
    }
    display: flex;
    flex-direction: column;
    // flex-grow: 1;
    width:80%;
    .content{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap:12px;
        & + .content {
            margin-top: 8px;
        }
        .titleinput, h4{
            min-width: 20%;
            &::first-letter{
                text-transform: capitalize;
            }
        }
    }
}

@media (max-width:"768px") {
    .information{

        .content{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            gap:12px;
            .titleinput, h4{
                min-width: 20%;
                &::first-letter{
                    text-transform: capitalize;
                }
            }
        }
    }
}