.title {
    &::first-letter{
        text-transform: capitalize;
    }
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;

    svg {
        cursor: pointer;
    }
    margin-bottom: 24px;
    h1 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        // font-size: x-large;

    }
}