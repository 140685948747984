.aviso {
    pointer-events: none;
    user-select: none;
    width: 100%;
    bottom: 0;
    max-width: 600px;
    color: #b60321;
    font-size: 10px;
    margin-top: -8px;
    margin-bottom: 15px;
    padding-left: 5px;
    text-shadow: 0px 0px 1px #a9131f54;
}