.container {
    width: 60%;
    height: 80%;
    background-color: white;
    border-radius: 8px;
    padding: 24px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;

    .form {
        width: 80%;
    }

    .grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 24px;
    }

    fieldset {
        border: none;
    }
}

@media(max-width:1190px) {
    .container {
        width: 60%;
        height: 80%;
        background-color: white;
        border-radius: 8px;
        padding: 20px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: auto;

        .form {
            width: 95%;
        }

        .grid {
            display: grid;
            grid-template-columns: 1fr;
            gap: 1px;
        }

        fieldset {
            padding: 10px;
            border: none;

            div {
                margin-top: 0px;
            }
        }

    }
}

@media(max-width:630px) {
    .container {
        width: 80%;
        height: 80%;
        background-color: white;
        border-radius: 8px;
        padding: 20px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: auto;

        .form {
            width: 95%;
        }

        .grid {
            display: grid;
            grid-template-columns: 1fr;
            gap: 1px;
        }

        fieldset {
            padding: 10px;

            div {
                margin-top: 0px;
            }
        }

    }
}

@media(max-width:380px) {
    .container {
        width: 90%;
        height: 80%;
        background-color: white;
        border-radius: 8px;
        padding: 20px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: auto;

        .form {
            width: 95%;
        }

        .grid {
            display: grid;
            grid-template-columns: 1fr;
            gap: 1px;
        }

        fieldset {
            padding: 10px;

            div {
                margin-top: 0px;
            }
        }

    }
}