@import '../../../Assets/theme/colors.module.scss';
.root{
    position: relative;
    // max-height: 90px;
    .container{
        padding: 4px 8px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // box-shadow: 1.8px 1.5px 1.8px 1.8px $lightgrey; 
        box-shadow:0px 0px 12px .5px  $lightgrey; 
        border-bottom: 8px solid $primary;
        min-width: 230px;
        // min-height: 80px;
        @media(max-width:1090px){
            min-width: 185px;
         }
        @media(max-width:890px){
            min-width: 165px;
         }
        .title{
            text-align: center;
            &::first-letter{
                text-transform: capitalize;
            }
            
            font-size: 13px;
            text-wrap: nowrap;
            @media(max-width:1090px){
                font-size: 11px;
                text-wrap: wrap;
                line-height: normal;
            }
            @media(max-width:890px){
                font-size: 10px;
                text-wrap: wrap;
                line-height: normal;
            }
            
        }
        .content {
            color: $primary;
            text-align: center;
            font-size: 1.7em;
            font-weight: 500;
        
            @media(max-width:890px) {
                font-size: 12px;
            }
        }
    }
    &.clickable {
        cursor: pointer;
    }
    

}


