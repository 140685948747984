@import '../../../../../Assets/theme/colors.module.scss';
.list{
    width: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}
.emptytext{
   font-size: 20px;
   font-weight: 800;
   color: $grey; 
   width: 80%;
   text-align: center;
}