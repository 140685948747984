.container {
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: center;
    
    .actions{
        display: flex; 
        flex-direction: row; 
        gap: 24px; 
        align-items: center;
    }

    & + .container {
        .text{
            width: 20%;
            // padding-right: 100px;
        }
    }
}

@media (max-width:768px) {
    .text{
       font-size: 12px;
    }
}