@import '../../Assets/theme/colors.module.scss';
.container{
    position: relative;
    padding: 24px;
    height: 30%;
    width: max(30%,360px);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: $primary;
    // @media(max-width:800px){
    //     height: auto;
    //     width: 80%;
    // }
    .label{
        margin-top: 12px;
        font-size: 32px;
        text-align: center;
    }
}   

.loader{
    position: relative;
    animation: spin 1.5s linear infinite;
    // width: 50%;
    // height: 50%;
    // margin-bottom: 12px;
  }
@keyframes spin {
    100%{
        transform: rotateZ(360deg);
    }
}

@media (max-width:768px) {
    .container{
        svg{
            scale: .8;
        }
    }
}