.selection{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    // width: 40%;
    @media(max-width:1300px){
        width: 70%;
        font-size: 14px;
    }
    @media(max-width:660px){
        flex-direction: column;
        gap: 0px;
        width: 100%;
        font-size: 14px;
    }   
    :last-child{
        max-width: 400px;
    }
}