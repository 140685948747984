.cards {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    justify-content: center;

    span {
        font-size: 16px;
        font-weight: 500;
    }

}

@media(max-width:600px) {
    .cards {
        flex-direction: column;
    }
}