.card{
    display: flex;
    justify-content: center;
    align-items: center;
}

.content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
}