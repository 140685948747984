@import '../../../../Assets/theme/colors.module.scss';

.header {
    position: relative;
    width: 100%;
}

.titlewrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        width: 100px;
        height: 100%;
        clip-path: circle();
    }

    .edit {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        cursor: pointer;
        color: $primary;
        position: absolute;
        transform: translate(50%, -50%);
        top: 50%;
        right: 50%;
    }
}

@media(max-width:600px) {
    .titlewrapper {
        img {
            width: 70px;
            height: 100%;
            clip-path: circle();
        }
    }
}