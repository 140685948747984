@import "../../Assets/theme/colors.module.scss";

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    max-height: 2.5rem !important;
    min-height: 2rem !important;
    background-color: white;
    color: $primary;
    border: 2px solid $primary;
    border-radius: 8px;
    transition: all .2s;
    width: fit-content;
    & span{
        font-weight: 550;
        &::first-letter{
            text-transform: uppercase;
        }
    }
    
    &:hover, &:focus-visible {
        // border: 2px solid white;
        // outline: white 2px solid;
        svg {
            color: white;
        }
        // box-shadow: .5px .7px 1.2px .5px $lightgrey;
        
        color: white;
        background-color: $primary;
    }
    
    &.danger {
        border-color: $error;
        background-color: $error;
        color: white;
        &:hover, &:focus-visible {
            color: $error;
            background-color: white;
        }
    }

    &:disabled {
        color: $grey;
        background-color: $lightgrey;
        border-color: $grey;
    }
}

@media(max-width:600px) {

    .button {
        max-height: fit-content !important;
        font-size: 0.9rem;
        
        svg {
            width: 25px;
            height: auto;
        }
    }

}