@import '../../../../../Assets/theme/colors.module.scss';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;

    /* button {
        width: 220px !important;
    } */

    .user {
        font-size: 18px;
        color: $primary;
        margin-top: 1px;
        margin-bottom: 20px;
    }

    .wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 16px;

        .error {
            color: $error;
            font-size: 16px;
            font-weight: 600;
        }
    }

}

.month {
    color: $primary;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    &::first-letter{
        text-transform: capitalize;
    }
}

.text {
    color: $grey;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 12px;
}