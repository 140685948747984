@import '../../../../../Assets/theme/colors.module.scss';
.root{
    display: flex;
    flex-direction: column;
    .container {
        display: flex;
        flex-direction: row;
        // flex-grow: .3;
        @media (max-width: 768px) {
            flex-direction: column;
        }
        .progress{
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            text-align: center;
            flex-grow: .3;
            gap:32px;
            .graph{
                margin: auto;
            }
        }
        .registerinfo {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            margin-bottom: 64px;
            gap: 16px;
            flex-grow: .4;
            height: 100%;
            color: $primary;
            .registerdesc{
                text-align: center;
            }
            // .chartdisplay {
            //     max-width: fit-content;
            //     display: flex;
            //     flex-direction: row;
            //     align-items: center;
            //     justify-content: center;
            //     gap: 16px;

            //     >span {
            //         max-width: 40%;
            //         text-align: justify;
            //     }

            //     .chart {
            //         position: relative;

            //         .percent {
            //             position: absolute;
            //             font-size: 14px;
            //         }

            //     }
            // }
        }

        .sections {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 8px;

            svg {
                width: 50px;
                height: 50px;
            }

        }
    }
}
@media(max-width:400px) {
    .root{
        .container {
            span {
                padding: 10px;
                font-size: 12px;
            }
    
            .chartwrapper {
                align-items: start;
                padding: 10px;
    
                h1 {
                    font-size: 14px;
                }
            }
        }
    }

}

@media(max-width: 600px) {
    .root{
        .container {
            .chartwrapper {
                .chartdisplay {
                    flex-direction: column;
                    max-width: 67%;
    
                    >span {
                        max-width: 100%;
                        padding: 5px;
                    }
                }
            }
    
            .sections {
                // margin-bottom: 30px;
                gap: 5px;
    
                svg {
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }


}

@media(max-width: 770px) {
   .root{
    .container {
        .sections {
            svg {
                width: 30px;
                height: 30px;
            }
        }
    }
   }
}

@media(max-width:800px) {
    .root{
        .container {
            .chartwrapper {
                align-items: start;
                padding: 10px;
    
                h1 {
                    font-size: 18px;
                }
            }
        }
    }

}