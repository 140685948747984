@import '../../../../../../../Assets/theme/colors.module.scss';
.container{
    border: 2px solid $primary;
    border-radius: 8px;
    // height: 100%;
    padding: 24px;
    margin: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .header{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 24px;
        min-width: 40%;
        justify-content: space-between;
        margin-bottom: 32px;
        .photo{
            border: 2.5px solid $primary;
            border-radius: 8px;
            width: 100px;
            height: 120%;
            overflow: hidden;
            img{
               object-fit: cover;
               height: 100%;
               width: 100%;
            }
        }
        .actions{
            display: flex;
            flex-direction: column;
            gap: 12px;
            align-self: flex-start;
            >*{
                align-self: start;
            }
        }
    }
    .information{
        > h2{
            text-decoration: underline solid $primary 15% ;
            margin-bottom: 12px;
        }
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        width:80%;
        >div{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap:12px;
            >h4{
                // display: inline-flex;
                // flex-grow: 1;
                &::first-letter{
                    text-transform: capitalize;
                }
            }
            >div{

                max-width: 80% !important;
            }
        }
    }
}