@import '../../Assets/theme/colors.module.scss';

.badge {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    clip-path: circle();
    width: 80px;
    height: 80px;
    background-color: $primary;
    position: absolute;
    right: 0;
    top: 0;
    span{
        font-size: 1.5rem;
        color: white;
        font-weight: bold;
        transition: scale .2s;
    }
    &:hover{
        span{
           scale: 1.1;
        }
    }
}

.modal {
    background-color: white;
    border-radius: 8px;
    padding: 16px 24px;
    overflow-y: scroll;
    width: 60%;
    height: 80%;
    h2{
        margin-bottom: 24px;
    }
    p{
        text-align: justify;
    //    line-height: 150%;
       &+p{
        margin-top: 1rem;
       }
    }
    .actions {
        margin-top: 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
}

@media(max-width:600px) {
    .badge {
        width: 50px;
        height: 50px;
        span{
            font-size: .8rem;
        }
    }

    .modal {
        width: 90%;
    }
}