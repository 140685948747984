@import '../../../../../../Assets/theme/colors.module.scss';
.container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: inherit;
    flex-grow: 1;
    .header{
       text-align: center;
    }
    .middle{
        text-align: center;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin-top: 64px;
        align-items: center;
        width: 100% !important;
        gap: 64px;
    }
    .member{
        color: $primary;
        font-size: 16px;
        font-weight: 600;
    }
}