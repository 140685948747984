@import '../../../Assets/theme/colors.module.scss';

.container {
    color: $primary;
    text-align: center;
    /* font-size: .8em; */
    font-weight: 500;

    @media(max-width:890px) {
        font-size: 12px;
    }
}