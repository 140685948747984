@import '../../../../Assets/theme/colors.module.scss';
.container {
    position: relative;
    counter-reset: section;
    .content {
        display: flex;
        flex-direction: column;
        margin: 32px 24px 0px 24px;

        .summary {
            display: flex;
            flex-direction: column;
            gap: 4px;
            font-size: 12px;
        }

        .table {
            margin-top: 24px;
            display: flex;
            flex-direction: column;

            
        }
        h3 {
            counter-increment: section;
            border-bottom: 2px solid $primary;
            min-width: fit-content;
            width: min(50%,300px);
            padding-bottom: 8px;
            margin-bottom: 16px;
            &::before{
                content: counter(section) ". ";
            }
            // font-size: 14px;
            // text-align: start;
        }
    }

    .options {
        position: absolute;
        right: 0;
        top: 0;
    }

    .actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 64px;
    }
}

@media(max-width:600px) {
    .container {
        .options {
            top: 41px
        }
    }

}