.container{
    display: flex;
    flex-direction: row;
    gap: 24px;
    margin-bottom: 32px;
}

@media (max-width:768px) {
    .container{
        flex-direction: column;
        align-items: center;
        >* {
            width: 200px;
        }
    }
}