.content{
    display: flex;
    flex-direction: column;
    .contentheader{
        display: flex;
        flex-direction: column;
        gap: 16px;
        justify-content: center;
        align-items: center;
        .logo{
            object-fit: initial;
            width: 100px;
            height: 100px;
            clip-path: circle();
        }
    }
}