@import '../../../../../Assets/theme/colors.module.scss';

@import '../../../../../Assets/theme/colors.module.scss';

.divRendaMediaFamiliar {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 544px;
    justify-content: center;
    margin-bottom: 50px;

}

.titleFullName {
    font-size: 18px;
    margin-bottom: 20px;
}

.titleRendaMediaFamiliar {
    color: $primary;
    font-size: 18px;
}

.valoresRendaMediaFamiliar {
    border: 1px solid $primary;
    padding: 4px 8px;
    border-radius: 8px;
    max-width: 150px;
    width: 100%;
    text-align: center;
}

.containerTeste {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 100%;
    max-width: 544px;
    flex-wrap: wrap;
    justify-content: space-between;

    h3 {
        font-weight: 500;
        font-size: 16px;
    }

    .buttonCadastrarDeclaracao {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        height: 25px;
        background-color: white;
        &::first-letter{
            text-transform: capitalize;
        }
        color: #1F4B73;
        border: 2px solid rgba(31, 75, 115, 1);
        font-weight: 600;
        border-radius: 5px;
        width: 100%;
        max-width: max-content;
        box-shadow: 0px 0px 4px rgba(31, 75, 115, 0.4)
    }
}

.containerRendaCadastrada {
    display: flex;
    width: 100%;
    max-width: 544px;
    flex-direction: column;
    margin: 30px 0px;
    padding: 10px 0px;

    h3 {
        display: flex;
        justify-content: center;
        font-weight: 500;
    }
}

.containerRendaCadastradaSituacao {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin: 20px 0px;


    h4 {
        font-size: 14px;
        color: $primary;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-grow: 1;
        font-weight: 500;
    }

    span {
        padding: 2px 8px;
        border-radius: 5px;
        font-weight: 500;


        &[updated=true] {
            background-color: white;
            border: 2px solid $success;
            color: $success;
        }

        &[updated=false] {
            background-color: white;
            border: 2px solid $error;
            color: $error;
        }
    }

}

.containerNenhumaRenda {
    margin-bottom: 60px;
    width: 100%;
    max-width: 544px;
    display: flex;

    p {
        width: 100%;
        font-size: 16px;
        text-align: justify;
        ;
    }
}

@media(max-width: 600px) {
    .divRendaMediaFamiliar {
        flex-direction: column;
        gap: 10px;
        padding: 10px 0px;
    }

    .titleRendaMediaFamiliar {
        font-size: 16px;
    }
}

@media(max-width:400px) {

    .containerNenhumaRenda {
        p {
            font-size: 16px;
            text-align: justify;
        }
    }

    .containerTeste {

        h3 {
            text-align: center;
        }
    }

    .containerRendaCadastrada {
        padding: 10px;
    }
}

@media(max-width:662px) {
    .containerNenhumaRenda {
        width: 100%;

    }

    .containerRenda {
        margin-bottom: 0px;

        div {
            flex-direction: column;
        }
    }

    .containerRendaCadastradaSituacao {

        flex-direction: column;

    }
}